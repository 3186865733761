<template>
  <div
    class="`
      h-72 rounded-2xl lg:h-72 w-72 phone:w-96 lg:w-1/2
      bg-backfill text-content flex flex-col items-center
      justify-between flex-shrink-0
      m-1 border-contrast border-2 shadow-md
      lg:m-4 my-4
      payment-plan-card
    `"
  >
    <div class="flex flex-col items-center justify-end w-full h-full p-2">

      <div class="flex justify-center w-full mb-4 h-1/4">
        <img
          class="h-full"
          src="img/logos/KIV-logo-transparent.png"
          alt=""
        >
      </div>

      <p class="text-2xl font-semibold opacity-50">{{plan.title}}</p>
      <div class="flex flex-col justify-center h-14">
        <input
          v-if="plan.promo"
          @keyup="$emit('update:input', $event.target.value)"
          name="Promo Code"
          autocomplete="off"
          :value="input"
          type="text"
          placeholder="KIV Promo Code..."
          @keyup.enter="$emit('enter')"
          class="`
            px-3 py-2 font-bold rounded-lg focus:outline-none bg-background
            min-w-0 text-xl
          `"
        />
        <p v-else class="p-2 text-3xl font-bold">{{plan.price}} </p>
      </div>
      <p class="mb-3 font-semibold opacity-50 text-md">{{plan.info}}</p>
      <button-large
        @click="$emit('click')"
        class="w-1/3 mb-2 text-black"
        color="primary"
        :disabled="plan.promo && input.length === 0"
        :pulse="pulse"
      >
        Select
      </button-large>
    </div>
  </div>
</template>

<script>
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'PaymentPlanCard',
  components: {
    ButtonLarge,
  },
  props: {
    plan: {
      type: Object,
      require: true,
    },
    input: {
      type: String,
      required: false,
      default: '',
    },
    pulse: {
      type: Boolean,
      requried: false,
      default: false,
    },
  },
};
</script>
