<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="$router.go(-1)"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :show-left-nav="false"
      :show-right-panel="false"
      :loading="loading"
      backButtonText="Projects"
      :notificationMessage.sync="notificationMessage"
      :notificationType="notificationType"
      :modal-show="showWalkthroughModal"
    >

      <template #modal>
        <walkthrough-modal
          v-if="showWalkthroughModal"
          title="Our gift to you!"
          @confirm="incrementWalkthrough"
          @cancel="cancelWalkthrough"
          description="
            As a first time user, you have the opportunity to claim a free project.
            All you need to do is submit the KIV Free promo code that will be entered for you!
          "
          textCancel='Finish'
          textConfirm='Claim Project!'
        />
      </template>

      <template #title>
        Payment
      </template>

      <template #content>
        <div class="flex flex-col items-center w-full h-full mb-3 lg:justify-center flex-center">

          <div class="`
            flex flex-col items-center justify-center flex-shrink-0 w-full mt-10 lg:flex-row
          `">

            <payment-plan-card
              v-for="plan in availablePlans" :key="plan.priceId"
              @click="selectPayment(plan.priceId)"
              :plan="plan"
              :pulse="pulsePromo && plan.priceId === 'PROMO'"
              :input.sync="promoCode"
            />

          </div>
          <div class="mb-4">
            <secondary-button-small
              @click="$router.go(-1)"
              class="w-20 my-3"
            >
              Back
            </secondary-button-small>
          </div>
        </div>
      </template>

    </interior-page-content-container>
</template>

<script>

import { USER_TRIGGER_PAYMENT, PROJECTS_ALL, USER_WALKTHROUGH_DISPATCH } from '@/store/actions';
import { USER_PLAN, USER_WALKTHROUGH_STATE } from '@/store/getters';
import { mapProjectFields } from '@/store/mappers';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import SecondaryButtonSmall from '@/components/shared/Buttons/SecondaryButtonSmall.vue';
import PaymentPlanCard from '@/components/projects/PaymentPlanCard.vue';
import WalkthroughModal from '@/components/walkthrough/WalkthroughModal.vue';

export default {
  name: 'PaymentPlan',
  components: {
    InteriorPageContentContainer,
    SecondaryButtonSmall,
    PaymentPlanCard,
    WalkthroughModal,
  },
  data() {
    return {
      loading: true,
      promoCode: 'KiVFreeProject',
      notificationType: 'success',
      notificationMessage: '',
    };
  },
  computed: {
    ...mapProjectFields(['projectsList']),
    showWalkthroughModal() {
      return this.$store.getters[USER_WALKTHROUGH_STATE] === 4;
    },
    pulsePromo() {
      return this.$store.getters[USER_WALKTHROUGH_STATE] === 5;
    },
    plans() {
      return [
        {
          title: 'Standard',
          price: 'Free',
          priceId: 'PROMO',
          description: 'Create one new KIV Project!',
          info: 'Single Project',
          visible: this.plan === null && this.projectsList.length === 0,
          promo: true,
        },
        {
          title: 'Standard',
          price: '$9.99',
          priceId: process.env.VUE_APP_STRIPE_STANDARD,
          description: 'Create one new KIV Project!',
          info: 'Single Project',
          visible: this.plan === null && this.projectsList.length === 0,
          promo: false,
        },
        {
          title: 'Premium',
          price: '$19.99',
          priceId: process.env.VUE_APP_STRIPE_PREMIUM,
          description: 'Create as many KIV Projects as you would like!',
          info: 'Unlimited Projects',
          visible: this.plan !== 'premium',
          promo: false,
        },
      ];
    },
    plan() { return this.$store.getters[USER_PLAN]; },
    availablePlans() {
      return this.plans.filter((plan) => plan.visible);
    },
  },
  async created() {
    const isNavigating = false;
    if (this.plan === 'Premium') this.$router.replace({ name: 'PaymentPlan' });
    else {
      await this.$store.dispatch(PROJECTS_ALL)
        .finally(() => { this.loading = isNavigating; });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters[USER_WALKTHROUGH_STATE] < 100) {
      if (to.name === 'ProjectCreate') {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
      } else if (this.$store.getters[USER_WALKTHROUGH_STATE] !== 0) {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
      }
    }
    next();
  },
  methods: {
    selectPayment(priceId) {
      const isPromo = priceId === 'PROMO';
      this.$store.dispatch(USER_TRIGGER_PAYMENT, {
        priceId: isPromo ? this.promoCode : priceId,
        isPromo,
      })
        .then(() => this.$router.push({ name: 'ProjectCreate' }))
        .catch(() => {
          this.notificationMessage = 'Invalid promo code! Ensure it spelled correctly with proper capitilization';
          this.notificationType = 'alert';
        });
    },
    incrementWalkthrough() {
      this.promoCode = 'KiVFreeProject';
      this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
    },
    cancelWalkthrough() {
      this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
    },
  },
};
</script>
