<template>
  <button
    @click="$emit('click')"
    :disabled="disable"
    :class="[
      disable ?
        'bg-gray-600 cursor-default text-gray-300' :
        'bg-tertiary hover:text-content',
      `
        flex flex-shrink-0 flex-grow-0 justify-center items-center
        px-2 py-1 text-sm font-bold text-white rounded bg-tertiary
        focus:outline-none
      `
      ]"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'SecondaryButtonSmall',
  props: {
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
